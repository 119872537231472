<template>
    <div class="basic-form">
        <div class="form-content">
            <Typography v-if="isArabic" class="title" variant="h3-display" as="h3">{{ subscribeTextAr }}</Typography>
            <Typography v-else-if="isRussian" class="title" variant="h3-display" as="h3">{{
                subscribeTextRu
            }}</Typography>
            <Typography v-else-if="isChinese" class="title" variant="h3-display" as="h3">{{
                subscribeTextZh
            }}</Typography>
            <Typography v-else class="title" variant="h3-display" as="h3">{{ subscribeTextEn }}</Typography>
            <Typography v-if="isDC" class="description" variant="body-display-small" as="p">{{
                descriptionTextDC
            }}</Typography>
            <Typography v-else-if="isCC" class="description" variant="body-display-small" as="p">{{
                descriptionTextCCEn
            }}</Typography>
            <Typography v-else-if="isCCZh" class="description" variant="body-display-small" as="p">{{
                descriptionTextCCZh
            }}</Typography>
            <Typography v-else-if="isCMEC" class="description" variant="body-display-small" as="p">{{
                descriptionTextCMECEn
            }}</Typography>
            <Typography v-else-if="isCMECAr" class="description" variant="body-display-small" as="p">{{
                descriptionTextCMECAr
            }}</Typography>
            <Typography v-else-if="isCE" class="description" variant="body-display-small" as="p">{{
                descriptionTextCE
            }}</Typography>
            <Typography v-else-if="isCREC" class="description" variant="body-display-small" as="p">{{
                descriptionTextCRECEn
            }}</Typography>
            <Typography v-else-if="isCRECRu" class="description" variant="body-display-small" as="p">{{
                descriptionTextCRECRu
            }}</Typography>
            <Typography v-else-if="isCI" class="description" variant="body-display-small" as="p">{{
                descriptionTextCI
            }}</Typography>
            <div v-if="isArabic" class="marketo-form ar">
                <form id="mktoForm_1021"></form>
                <div id="confirmFormCTWEngage" style="display: none">
                    <strong>شكرا لك</strong>
                    <p>
                        الرجاء العودة إلى رسالة في بريدكم الإلكتروني تحوي رابطاً إلى موقع كارنيغي carnegiendowment.org،
                        حيث يمكنكم تعديل موادكم المفضَّلة.
                    </p>
                </div>
            </div>
            <div v-else-if="isRussian" class="marketo-form">
                <form id="mktoForm_1023"></form>
                <div id="confirmFormCTWEngage" style="display: none">
                    <p>
                        Пожалуйста, проверьте электронную почту. Для подтверждения регистрации пройдите по ссылке в
                        письме, которое мы Вам отправили.
                    </p>
                </div>
            </div>
            <div v-else-if="isChinese" class="marketo-form">
                <form id="mktoForm_1020"></form>
                <div id="confirmFormCTWEngage" style="display: none">
                    <strong>谢谢</strong>
                    <p>
                        请查看您的电子邮件，其中包含登录到carnegieendowment.org网站的链接，您可通过此链接修改个人偏好。
                    </p>
                </div>
            </div>
            <div v-else class="marketo-form">
                <form id="mktoForm_1012"></form>
                <div id="confirmFormCTWEngage" style="display: none">
                    <strong>Thank you!</strong>
                    <p>Check your email for details on your request.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { onUpdated } from 'vue';
const props = defineProps({
    formContextData: {
        type: Object,
    },
    subscribeTextEn: {
        type: String,
        default: 'Subscribe Today',
    },
    subscribeTextAr: {
        type: String,
        default: 'إشترك اليوم',
    },
    subscribeTextRu: {
        type: String,
        default: 'Подписаться',
    },
    subscribeTextZh: {
        type: String,
        default: '马上订阅',
    },
    descriptionTextDC: {
        type: String,
        default:
            'Subscribe today to receive invitations to events from the Carnegie Endowment for International Peace.',
    },
    descriptionTextCCEn: {
        type: String,
        default: 'Subscribe today to receive invitations to events from Carnegie China.',
    },
    descriptionTextCCZh: {
        type: String,
        default: '获取最新资讯和见解来自卡内基中国',
    },
    descriptionTextCMECEn: {
        type: String,
        default:
            'Subscribe today to receive invitations to events from the Malcolm H. Kerr Carnegie Middle East Center.',
    },
    descriptionTextCMECAr: {
        type: String,
        default: 'تسجّلوا لتلقي رسائل إلكترونية من مركز كارنيغي للشرق الأوسط',
    },
    descriptionTextCE: {
        type: String,
        default: 'Subscribe today to receive invitations to events from Carnegie Europe.',
    },
    descriptionTextCRECEn: {
        type: String,
        default: 'Subscribe today to receive invitations to events from the Carnegie Russia Eurasia Center.',
    },
    descriptionTextCRECRu: {
        type: String,
        default: 'Свежая аналитика экспертов Берлинского центра Карнеги — в вашей электронной почте.',
    },
    descriptionTextCI: {
        type: String,
        default: 'Subscribe today to receive invitations to events from Carnegie India.',
    },
});
const { locale } = useLocale();
const { currentCenter } = useCenters();
let isDC = false;
let isCC = false;
let isCCZh = false;
let isCMEC = false;
let isCMECAr = false;
let isCE = false;
let isCREC = false;
let isCRECRu = false;
let isCI = false;
let isArabic = false;
let isRussian = false;
let isChinese = false;
isDC = computed(() => {
    return currentCenter.value.slug === 'global';
});
isCC = computed(() => {
    return currentCenter.value.slug === 'china' && locale._value !== 'zh';
});
isCCZh = computed(() => {
    return currentCenter.value.slug === 'china' && locale._value === 'zh';
});
isCMEC = computed(() => {
    return currentCenter.value.slug === 'middle-east' && locale._value !== 'ar';
});
isCMECAr = computed(() => {
    return currentCenter.value.slug === 'middle-east' && locale._value === 'ar';
});
isCE = computed(() => {
    return currentCenter.value.slug === 'europe';
});
isCREC = computed(() => {
    return currentCenter.value.slug === 'russia-eurasia' && locale._value !== 'ru';
});
isCRECRu = computed(() => {
    return currentCenter.value.slug === 'russia-eurasia' && locale._value === 'ru';
});
isCI = computed(() => {
    return currentCenter.value.slug === 'india';
});
isArabic = computed(() => {
    return locale._value === 'ar';
});
isRussian = computed(() => {
    return locale._value === 'ru';
});
isChinese = computed(() => {
    return locale._value === 'zh';
});

function formNewsletterEnCC() {
    MktoForms2.loadForm('//Pages.carnegieendowment.org', '813-XYU-422', 1012, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CC Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'beijing' });
            form.addHiddenFields({ englishNewsletterAll: 'ctcEnglish' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formNewsletterEnCMEC() {
    MktoForms2.loadForm('//Pages.carnegieendowment.org', '813-XYU-422', 1012, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CMEC Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'beirut' });
            form.addHiddenFields({ englishNewsletterAll: 'menaTransitions' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}

function formNewsletterEnCE() {
    MktoForms2.loadForm('//Pages.carnegieendowment.org', '813-XYU-422', 1012, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CE Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'brussels' });
            form.addHiddenFields({ englishNewsletterAll: 'communique' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formNewsletterEnCREC() {
    MktoForms2.loadForm('//Pages.carnegieendowment.org', '813-XYU-422', 1012, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CREC Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'moscow' });
            form.addHiddenFields({ englishNewsletterAll: 'cmcEnglish' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formNewsletterEnCI() {
    MktoForms2.loadForm('//Pages.carnegieendowment.org', '813-XYU-422', 1012, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CI Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'newDelhi' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formNewsletterEn() {
    MktoForms2.loadForm('//Pages.carnegieendowment.org', '813-XYU-422', 1012, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CTW Subscribe Mkto En' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'dc' });
            form.addHiddenFields({ englishNewsletterAll: 'ctw' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formNewsletterAr() {
    MktoForms2.loadForm('//Pages.carnegieendowment.org', '813-XYU-422', 1021, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'MENATransitions Subscribe Mkto Ar' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ centerAffiliationAll: 'beirut' });
            form.addHiddenFields({ nonEnglishNewsletterAll: 'cmecArabic' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formNewsletterRu() {
    MktoForms2.loadForm('//Pages.carnegieendowment.org', '813-XYU-422', 1023, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CMC SubscribePage Mkto Ru' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ nonEnglishNewsletterAll: 'cmcRussian' });
            form.addHiddenFields({ centerAffiliationAll: 'moscow' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}
function formNewsletterZh() {
    MktoForms2.loadForm('//Pages.carnegieendowment.org', '813-XYU-422', 1020, function (form) {
        // Set values for the hidden fields, "Site_Source__c"
        // Note that these fields were configured in the form editor as hidden fields already
        form.vals({ Site_Source__c: 'CC SubscribePage Mkto Zh' });
    });
    MktoForms2.whenReady(function (form) {
        //Add an onSubmit to add hidden field values
        form.onSubmit(function (form) {
            form.addHiddenFields({ nonEnglishNewsletterAll: 'ctcChinese' });
            form.addHiddenFields({ centerAffiliationAll: 'beijing' });
        });
        //Add an onSuccess handler
        form.onSuccess(function (values, followUpUrl) {
            //get the form's jQuery element and hide it
            form.getFormElem().hide();
            document.getElementById('confirmFormCTWEngage').style.display = 'block';
            //return false to prevent the submission handler from taking the lead to the follow up url.
            return false;
        });
    });
}

onMounted(() => {
    (function(){
        function doOrQueueFormsStuff() {
            if (typeof MktoForms2 != "object") {
                document.addEventListener("load", doOrQueueFormsStuff, true);
            } else if (!doOrQueueFormsStuff.done) {
                document.removeEventListener("load", doOrQueueFormsStuff, true);
                doOrQueueFormsStuff.done = true;
                if (locale._value === 'en') {
                    if (isCC.value) {
                        formNewsletterEnCC();
                    } else if (isCMEC.value) {
                        formNewsletterEnCMEC();
                    } else if (isCE.value) {
                        formNewsletterEnCE();
                    } else if (isCREC.value) {
                        formNewsletterEnCREC();
                    } else if (isCI.value) {
                        formNewsletterEnCI();
                    } else {
                        formNewsletterEn();
                    }
                }
                if (locale._value === 'ar') {
                    formNewsletterAr();
                }
                if (locale._value === 'ru') {
                    formNewsletterRu();
                }
                if (locale._value === 'zh') {
                    formNewsletterZh();
                }
            }
        }
        doOrQueueFormsStuff();
    })();
});
</script>

<style lang="scss" scoped>
.basic-form {
    text-align: center;
    padding: 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    .title,
    .description {
        margin-bottom: 2rem;
    }
    .description {
        color: color(noir, default, 0.7);
    }
    .form-content {
        max-width: 60rem;
    }
}
@import './././assets/styles/core/marketoForms';
</style>
